// Node Modules
import React from 'react';

// Components
import VirtualFitHero from '../../components/content-sections/course/VirtualFitHero';
import CourseContent from '../../components/content-sections/course/CourseContent';
import JoinUsFormWrapper from '../../components/form/presentational/JoinUsFormWrapper';
import Layout from '../../components/core/layout';
import SEO from '../../components/core/seo';

const CourseVirtualFit = () => (
  <Layout displayStickyButton={false}>
    <SEO title="Assuming Remote Control Course" />
    <VirtualFitHero />
    <CourseContent
      dripColor="#949999"
      title="Virtual Fit Module |&nbsp;15&nbsp;Minutes"
      description="Helps leaders build successful remote teams by outlining and identifying the ideal characteristics of remote&nbsp;employees."
      renderTopics={() => (
        <ul className="greater-than-list">
          <li>Five Traits of Successful Remote Employees</li>
          <li>Being a Self-Starter</li>
          <li>Effective Communication Skills</li>
          <li>Time Management Skills</li>
          <li>Critical Thinking and Problem-Solving Skills</li>
          <li>Technical Aptitude</li>
          <li>Interview Resource Guide (PDF)</li>
        </ul>
      )} />
    <JoinUsFormWrapper buttonText="Register" cta="Register for Virtual Fit" hideCourses hideInterest interest="Register" selectedCourse="virtualFit" />
  </Layout>
);

export default CourseVirtualFit;
